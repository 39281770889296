/* all */

.background {
    margin-top: 4rem;
    background: var(--backgroundCustom);
    color: black;
}

.header {
    text-align: center;
    padding: 4rem 1rem 0 1rem;
    color: rgb(var(--accent));
    font-size: 3rem;
}

.desc {
    text-align: center;
    padding: 4rem 1rem 0 1rem;
    font-size: 1.5rem;
    max-width: 1000px;
    margin: auto;
}

.calendar {
    max-width: 1200px;
    margin: auto;
    padding: 4rem 2rem;
}

.calendar_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 0.2rem solid rgba(var(--accent), 1);
    border-radius: 2rem;
    padding: 2rem;
    position: relative;
}

.calendar_reservation {
    min-width: 90%;
    min-height: 90%;
    padding: 2rem;
    position: absolute;
    top: 50%;
    left: 50%;
    background: rgba(255, 255, 255, 1);
    border: 0.2rem solid rgba(var(--accent), 1);
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
    transform: translate(-50%, -50%) scale(0);
    transition: transform 0.5s ease-out;
}

.calendar_reservationVisible {
    transform: translate(-50%, -50%) scale(1);
}


.calendar_reservationHeader {
    display: flex;
    align-items: center;
    padding-bottom: 2rem;
}

.calendar_reservationHeaderImg {
    height: 3.5rem;
    width: 3.5rem;
    padding: .5rem
}

.calendar_reservationHeaderDesc {
    padding-left: 10px;
    font-size: 1.5rem;
    color: black;
    font-family: 'Kalam', cursive;
    font-weight: bold;
}

.calendar_reservationText1 {
    font-size: 1.5rem;
    padding-bottom: 2rem;
}

.calendar_reservationText2 {
    font-size: 1.5rem;
    padding-bottom: 3rem;
}


.inputContainer {
    width: 100%;
    position: relative;
}

.input {
    width: 100%;
    padding: 1rem;
    margin-bottom: 3rem;
    align-self: center;
    border: 2px transparent solid;
    color: black;
    font-size: 1.5rem;
    outline: none;
    -webkit-appearance: none;
    box-shadow: 0 0 10px rgba(var(--accent), 0.5);
}


.inputIsInvalid {
    border: red 2px solid;
}

.label {
    font-size: 1.5rem;
    padding: 1rem;
    color: rgba(0, 0, 0, 0.6);
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    white-space: nowrap;
    transition: 0.5s ease;
}

.input:focus+.label,
.input:required:valid+.label {
    /* to work in htmml must be required:true*/
    display: flex;
    transform: scale(0.7) translate(-1rem, -4rem);
    color: rgb(var(--accent));
}

.input:focus {
    border-left: rgba(var(--accent), 0.5) .3rem solid;
    border-right: rgba(var(--accent), 0.5) .3rem solid;
}

/* Change background and color if input is autocompleate ;) */
.input:-webkit-autofill,
.input:-webkit-autofill:hover,
.input:-webkit-autofill:focus,
.input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s, color 9999s ease-in-out 0s;
}

.input::placeholder {
    color: rgba(255, 255, 255, 0.6)
}

.inputCheckBoxContainer {
    width: 100%;
    font-size: 1rem;
    display: flex;
    align-items: center;
}

.inputCheckBox {
    width: 1.2rem;
    height: 1.2rem;
}

.labelCheckBox {
    font-size: 1.2rem;
    padding: 0.5rem;
}

.inputIsInvalidCheckBox {
    outline: 0.2rem solid red;
}

.calendar_reservationButtonsContainer {
    display: flex;
    justify-content: center;
    align-items: center;

}

.calendar_reservationButton {
    padding: 0.5rem 2rem;
    margin: 2rem;
    font-size: 1rem;
    outline: none;
    border: 0.1rem solid rgba(var(--accent), 1);
    background: white;
    box-shadow: 0 0 10px rgba(var(--accent), 0.5);
}

.calendar_reservationButton:hover {
    box-shadow: 0 0 20px rgba(var(--accent), 0.5);
}

.calendar_reservationButton:active {
    box-shadow: none;
    background: rgba(var(--accent), 1);
    color: white;
}


.calendar_header {
    display: flex;
    align-items: center;
}

.calendar_headerImg {
    height: 3.5rem;
    width: 3.5rem;
    padding: .5rem
}

.calendar_headerDesc {
    padding-left: 10px;
    font-size: 1.5rem;
    color: black;
    font-family: 'Kalam', cursive;
    font-weight: bold;
}

.calendar_service {
    width: 60%;
    padding-top: 2rem;
}

.calendar_serviceDesc {
    padding-bottom: 1rem;
}

.calendar_serviceList {
    background-color: white;
    color: rgba(var(--accent), 1);
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    border: 0.1rem solid rgba(var(--accent), 0.3);
    box-shadow: 0 0 20px rgba(var(--accent), 0.3);
    cursor: pointer;
    outline: none;
}

.calendar_date {
    width: 100%;
    height: 100%;
    padding-top: 2rem;
}

.calendar_dateDesc {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    padding: 0 0 1rem 0;
}


.calendar_dateContainer {
    width: 100%;
    height: 100%;
    position: relative;
}


.calendar_hours {
    padding: 2rem 0 0 0;
    width: 60%;
}

.calendar_hoursDesc {
    padding-bottom: 1rem;
}

.calendar_hoursList {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(5rem, 1fr));

}

.calendar_hoursListItem {
    padding: 0.5rem;
    margin: 0.2rem;
    font-size: 1rem;
    text-align: center;
}


@media (max-width:500px) {
    .calendar {
        padding: 4rem 1rem;
    }

    .calendar_container {
        border-radius: 1rem;
        padding: 1rem;
    }

    .calendar_reservation {
        padding: 1rem;
    }

    .calendar_service {
        width: 80%;
    }

    .calendar_hours {
        width: 80%;
    }
}