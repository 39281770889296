.background {
    min-height: calc(100vh - 10rem);
    margin: 4rem 0 0 8rem;
    background: var(--backgroundCustom);
    color: black;
    position: relative;
}

/* nav */

.nav {
    background: linear-gradient(90deg, rgba(100, 100, 100, 0.8) 0%, rgba(50, 50, 50, 0.8) 100%);
    color: white;
    position: fixed;
    top: 4rem;
    left: 0;
    width: 8rem;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.nav_img {
    height: 4rem;
    width: 4rem;
    align-self: center;
    margin: 1rem 0;
}

.nav_item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem;
    cursor: pointer;
}

.nav_itemLogout {
    margin-top: 2rem;
}

.nav_item:hover {
    background: black;
}

.nav_item:first-of-type {
    margin-top: 2rem;
}

.nav_icon {
    width: 1.5rem;
    height: 1.5rem;
    fill: white;
}

.nav_text {
    font-size: 1rem;
    padding-left: 0.5rem;
}

/* header */
.header {
    text-align: center;
    padding: 4rem 1rem 0 1rem;
    color: rgb(var(--accent));
    font-size: 3rem;
}

.desc {
    text-align: center;
    padding: 4rem 1rem 0 1rem;
    font-size: 1.5rem;
    max-width: 1000px;
    margin: auto;
}

/* login */

.login {
    max-width: 800px;
    padding: 2rem;
    margin: 2rem auto;
    border-radius: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.login_desc {
    font-size: 1rem;
    padding-bottom: 2rem;
}

.login_a {
    text-decoration: none;
}

.login_inputContainer {
    width: 100%;
    position: relative;
}

.login_input {
    width: 100%;
    padding: 1rem;
    margin-bottom: 3rem;
    align-self: center;
    border: 2px transparent solid;
    color: black;
    font-size: 1.5rem;
    outline: none;
    -webkit-appearance: none;
    box-shadow: 0 0 10px rgba(var(--accent), 0.5);
}


.login_inputIsInvalid {
    border: red 2px solid;
}

.login_label {
    font-size: 1.5rem;
    padding: 1rem;
    color: rgba(150, 150, 150, 1);
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    white-space: nowrap;
    transition: 0.5s ease;
}

.login_icon {
    width: 2rem;
    height: 2rem;
    position: absolute;
    right: 1rem;
    top: 1rem;
    fill: rgb(var(--accent));
}

.login_input:focus+.login_label,
.login_input:required:valid+.login_label {
    /* to work in htmml must be required:true*/
    display: flex;
    transform: scale(0.7) translate(-1rem, -4rem);
    color: rgb(var(--accent));
}

.login_input:focus {
    border-left: rgba(var(--accent), 0.5) .3rem solid;
    border-right: rgba(var(--accent), 0.5) .3rem solid;
}

/* Change background and color if input is autocompleate ;) */
.login_input:-webkit-autofill,
.login_input:-webkit-autofill:hover,
.login_input:-webkit-autofill:focus,
.login_input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s, color 9999s ease-in-out 0s;
}

.login_input::placeholder {
    color: rgba(150, 150, 150, 1);
}

.login_inputCheckBoxContainer {
    width: 100%;
    font-size: 1rem;
    display: flex;
    align-items: center;
}

.login_inputCheckBox {
    width: 1.2rem;
    height: 1.2rem;
}

.login_labelCheckBox {
    font-size: 1.2rem;
    padding: 0.5rem;
}

.login_inputIsInvalidCheckBox {
    outline: 0.2rem solid red;
}

.login_buttonsContainer {
    display: flex;
    justify-content: center;
    align-items: center;

}

.login_button {
    padding: 0.5rem 2rem;
    margin: 2rem;
    font-size: 1rem;
    outline: none;
    border: 0.1rem solid rgba(var(--accent), 1);
    background: white;
    box-shadow: 0 0 10px rgba(var(--accent), 0.5);
}

.login_button:hover {
    box-shadow: 0 0 20px rgba(var(--accent), 0.5);
}

.login_button:active {
    box-shadow: none;
    background: rgba(var(--accent), 1);
    color: white;
}

/* calendar */

.calendar {
    max-width: 1200px;
    margin: auto;
    padding: 4rem 2rem;

}

.calendar_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 0.2rem solid rgba(var(--accent), 1);
    border-radius: 2rem;
    padding: 2rem;
    position: relative;
}

.calendar_header {
    display: flex;
    align-items: center;
}

.calendar_headerImg {
    height: 3.5rem;
    width: 3.5rem;
    padding: .5rem
}

.calendar_headerDesc {
    padding-left: 10px;
    font-size: 1.5rem;
    color: black;
    font-family: 'Kalam', cursive;
    font-weight: bold;
}

.calendar_date {
    width: 100%;
    height: 100%;
    padding-top: 2rem;
}

.calendar_dateDesc {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    padding: 0 0 1rem 0;
}

.calendar_dateContainer {
    width: 100%;
    height: 100%;
    position: relative;
}

.calendar_hours {
    padding: 2rem 0 0 0;
    width: 60%;
}

.calendar_hoursTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1rem;
}

.calendar_hoursDesc {
    font-size: 1rem;
}

.calendar_hoursButton {
    padding: 0.2rem 2rem;
    font-size: 1rem;
    border: 0.15rem solid rgba(var(--accent), 1);
    outline: none;
}

.calendar_hoursButton:hover {
    background: rgba(var(--accent), 0.1);
    box-shadow: 0 0 10px rgba(var(--accent), 1);

}

.calendar_hoursButton:active {
    background: rgba(var(--accent), 1);
    color: white;
    box-shadow: 0 0 20px rgba(var(--accent), 1);
}

.calendar_hoursList {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

}

.calendar_hoursListItem {
    width: 100%;
    padding: 0.5rem;
    margin: 0.2rem;
    font-size: 1rem;
    text-align: center;
    display: flex;
    align-items: center;
    border: 0.1rem solid rgba(var(--accent), 1);
}


.calendar_hoursListItemInput {
    width: 1.2rem;
    height: 1.2rem;
}

.nav_iconHoursListItem {
    width: 1.2rem;
    height: 1.2rem;
    min-width: 1.2rem;
    min-height: 1.2rem;
}

.nav_iconHoursListItem:active {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
}

.calendar_hoursListItemLabel {
    font-size: 1rem;
    padding: 0.5rem;
}

.calendar_hoursListItemDesc {
    font-size: 1rem;
    padding: 0.5rem;
}

.calendar_reservationListItem {
    width: 100%;
    padding: 0.5rem;
    margin: 0.2rem;
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    border: 0.1rem solid rgba(var(--accent), 1);
    position: relative;
}

.calendar_reservationListItemDate {
    font-size: 1rem;
}

.calendar_reservationListItemDesc {
    padding-left: 1rem;
    font-size: 1.2rem;
    padding-top: 0.5rem;
}


@media (max-width: 700px) {
    .calendar {
        padding: 4rem 1rem;
    }

    .calendar_container {
        border-radius: 1rem;
        padding: 1rem;
    }
}

@media (max-width: 1000px) {
    .calendar_hours {
        width: 100%;
    }


}